import React, {useState} from 'react';
import {Button, Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import HeaderView from 'view/common/Header';
import backgroundMain1 from 'asset/imageV2/background_main1.svg';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import imageNotebook from 'asset/imageV2/image_notebook.png';
import iconArrowRight from 'asset/imageV2/icon_arrow_right.png';
import iconDownload from 'asset/imageV2/icon_download.png';
import iconCert1 from 'asset/imageV2/icon_cert1.svg';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import imgMain1 from 'asset/imageV2/image_main1.png';
import imgMain2 from 'asset/imageV2/image_main2.png';
import imgMain3 from 'asset/imageV2/image_main3.png';
import imgMain5 from 'asset/imageV2/image_main5.png';
import samplePDF from 'asset/pdf/sample.pdf';
import {mainColoredBtn, iconBox} from 'view/main/Styles';
import FooterView from 'view/common/Footer';
import AutoSlider from 'view/common/AutoSlider';
import SideOverlay1 from 'view/common/SideOverlay1';
import SchoolView from 'view/common/SchoolView';
import APIUtils from 'common/utils/APIUtils';
import {GetRunningPopup} from 'common/utils/GraphQLUtils';

const MainPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);

  function downloadSamplePDF() {
    const pdfBlob = new Blob([samplePDF], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = samplePDF;

    a.download = 'KEEwiT_sample.pdf';
    document.body.appendChild(a);
    a.click();
    setTimeout(_ => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  function showMainButton() {
    if (authReducer.isLogin) {
      return (
        <Button
          variant="standard"
          style={mainColoredBtn}
          onClick={() => {
            APIUtils.LoggerLog(
              authReducer.user_id,
              'keewit',
              'info',
              window.location.pathname,
              'navigate_mypage',
            );
            navigate('/mypage');
          }}
        >
          {'마이페이지'}
          <img
            src={iconArrowRight}
            alt="iconArrowRight"
            style={{marginLeft: '1rem', width: '1.5rem'}}
          />
        </Button>
      );
    }
    return (
      <Button
        variant="standard"
        style={mainColoredBtn}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewit',
            'info',
            window.location.pathname,
            'navigate_login',
          );
          navigate('/login');
        }}
      >
        {'회원가입/로그인'}
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{marginLeft: '1rem', width: '1.5rem'}}
        />
      </Button>
    );
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <GetRunningPopup />
      <SideOverlay1 />
      <HeaderView />
      <Box
        style={{
          width: '80%',
          maxWidth: '80rem',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '3rem',
        }}
      >
        <Box style={{flex: 4}}>
          <Box
            style={{
              fontSize: '1rem',
              color: '#49545c',
              marginTop: '12rem',
            }}
          >
            첨삭시간은 짧아지고 피드백은 강해지는 글 첨삭 AI
          </Box>
          <Box
            style={{
              fontSize: '2.625rem',
              color: '#2f3941',
              fontWeight: '700',
              marginTop: '1rem',
            }}
          >
            1시간 첨삭을
          </Box>
          <Box
            style={{fontSize: '2.625rem', color: '#2f3941', fontWeight: '700'}}
          >
            <span style={{color: '#35d2bb'}}>5분 안에</span> 끝내세요!
          </Box>
          <Box style={{marginTop: '2rem', marginBottom: '5rem'}}>
            {showMainButton()}
          </Box>
        </Box>
        <Box
          style={{
            flex: 6,
            position: 'relative',
          }}
        >
          <img
            src={imageNotebook}
            alt="imgnotebook"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
          <img
            src={backgroundMain1}
            alt={'bg1'}
            style={{
              width: '120%',
              height: '100%',
              zIndex: '-1',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
              marginLeft: '-20%',
            }}
          />
        </Box>
      </Box>

      {/* <SchoolView /> */}
      <Box
        style={{
          marginTop: '6rem',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box style={{color: '#3cd9c2', fontWeight: '700'}}>검증된 전문성</Box>
        <Box
          style={{fontSize: '1.75rem', fontWeight: '700', marginTop: '1rem'}}
        >
          키위티의 다양한 인증정보를 확인하세요.
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: '4rem',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert6}
                alt="iconCert1"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>2024 국가공헌대상</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              부총리 겸 교육부 장관상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert5}
                alt="iconCert2"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>2024 이러닝-에듀테크</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              비즈니스 모델 최우수상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert4}
                alt="iconCert3"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>19회 에듀테크 우수기업 콘테스트</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              콘텐츠·서비스 분야 우수상
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert3}
                alt="iconCert4"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>한국정보통신기술협회</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              GS (good soft) 1등급 인증
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box style={iconBox}>
              <img
                src={iconCert1}
                alt="iconCert5"
                style={{
                  height: '50%',
                }}
              />
            </Box>
            <Box>조달청 인증</Box>
            <Box style={{color: '#3cc5b0', fontWeight: '700'}}>
              벤처창업 혁신상품
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '6rem',
          width: '80%',
          display: 'flex',
          maxWidth: '80rem',
        }}
      >
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            <span style={{color: '#35d2bb'}}>표준화된</span>
          </Box>
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>채점 엔진</Box>
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}></Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위티에 사용하는{' '}
            <span style={{fontWeight: '700'}}>
              키위 AI 글 평가 모델은 정규분포
            </span>
            를 따릅니다. S교육브랜드의 2만건 데이터와 J초등학교의 400건의 글
            데이터를 채점한 결과 키위 AI 글 평가 모델이 표준화된 평가 기준을
            따르며, 타당도 높은 평가를 수행하고 있음이 증명되었습니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain1} alt="imgMain1" style={{width: '60%'}} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            신뢰도 높은
          </Box>
          <Box
            style={{fontSize: '1.75rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            객관적 평가
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            AI허브 에세이 평가 5만건에 대해 평가자 간의 상관도보다, 평가자와
            키위 AI 간의 상관도가 더 높은 결과를 보였습니다. 키위 AI는 평가자
            간의{' '}
            <span style={{fontWeight: '700'}}>
              주관성을 배제한 객관적인 평가를 수행
            </span>
            하여, 신뢰도 높은 평가를 수행합니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain2} alt="imgMain2" style={{width: '70%'}} />
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: '80%',
          maxWidth: '80rem',
          display: 'flex',
        }}
      >
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            선생님-학생의
          </Box>
          <Box
            style={{fontSize: '1.75rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            글쓰기 과제 연동
          </Box>
          <Box style={{marginTop: '3.5rem'}}>
            키위런은 키위티의 학생용 서비스로, 키위티에 종속되어 있습니다.
            키위런 학생과 연결하여 글쓰기 과제를 생성하고 관리하세요. 최종
            리포트는 선생님의 피드백을 반영하여 학생에게 공유할 수 있습니다.
          </Box>
          <Box>
            <span style={{fontWeight: '700'}}>간편한 글쓰기 과제</span>,
            키위티는 가능합니다.
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              flex: 1,
            }}
          >
            <img src={imgMain3} alt="imgMain3" style={{width: '70%'}} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: '#f9f9f9',
            margin: '1rem 1rem',
            padding: '2.5rem 2.5rem 0rem 2.5rem',
            borderRadius: '1.25rem',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Box style={{fontSize: '1.75rem', fontWeight: 'bold'}}>
            한 눈에 보는 분석 결과
          </Box>
          <Box
            style={{fontSize: '1.75rem', color: '#35d2bb', fontWeight: 'bold'}}
          >
            전문적인 리포트
          </Box>
          <Box
            style={{
              marginTop: '3.5rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // width: '14rem',
              }}
            >
              <Box>
                키위티 리포트는 한 눈에 분석 결과를 확인할 수 있습니다.{' '}
                <span style={{fontWeight: '700'}}>총평과 통계 자료</span>는
                물론,{' '}
                <span style={{fontWeight: '700'}}>
                  AI가 분석한 글 구조를 포함한 레이더차트, 워드클라우드, 버블
                  차트
                </span>{' '}
                등이 출력됩니다. 이어 글 전체와 문단별 피드백이 함께 제공됩니다.
                <Box>(*글 종류에 따라 제공 사항은 달라질 수 있습니다.)</Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '3rem',
                  marginBottom: '2rem',
                }}
              >
                <Button
                  variant="standard"
                  style={mainColoredBtn}
                  onClick={() => {
                    APIUtils.LoggerLog(
                      authReducer.user_id,
                      'keewit',
                      'info',
                      window.location.pathname,
                      'click_event_download_sample_pdf',
                    );
                    downloadSamplePDF();
                  }}
                >
                  {'리포트 예시 다운받기'}
                  <img
                    src={iconDownload}
                    alt="iconDownload"
                    style={{marginLeft: '1rem', width: '1.5rem'}}
                  />
                </Button>
              </Box>
            </Box>
            {/* <Box
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
                flex: 1,
              }}
            >
              <img src={imgMain4} alt="imgMain4" style={{width: '100%'}} />
            </Box> */}
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          marginTop: '6rem',
          width: '100%',
          height: '38rem',
          background: 'rgba(240, 251, 250, 0.50)',
          position: 'relative',
        }}
      >
        <img
          src={backgroundMain2}
          alt="backgroundMain2"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center top',
          }}
        />
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.75rem',
            fontWeight: '700',
            paddingTop: '6rem',
          }}
        >
          키위 평가 지표
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          키위 평가 엔진은 1960년대부터 연구된 영미권 글쓰기 평가의 기준을
          한국어 글쓰기 평가에 적용하였습니다.
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          AI의 정량적 평가 루브릭은 ‘좋은 글의 6가지 특성’을 기준으로
          구성되었습니다.
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          ‘주제의 명확성’, ‘조직과 전개’, ‘어휘의 풍부성’, ‘문장 구사력’,
          ‘의도의 표현’,
        </Box>
        <Box
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#49545C',
            letterSpacing: '-0.03rem',
          }}
        >
          ‘문법의 정확성’의 6가지 지표로 피드백을 수행합니다.
        </Box>
        <AutoSlider />
      </Box>
      <Box
        style={{
          width: '70%',
          maxWidth: '80rem',
          marginTop: '7rem',
          marginBottom: '5rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#3cd9c2', fontWeight: '700'}}>FAQ</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
            }}
          >
            자주 묻는 질문
          </Box>
          <Box style={{display: 'block'}}>
            <Button
              style={{
                display: 'block',
                marginTop: '2rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand1',
                );
                setExpand1(prev => !prev);
              }}
            >
              Q. 키위티와 키위런의 차이는 무엇인가요?
            </Button>
            <Box
              style={{
                display: expand1 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티는 선생님용 서비스입니다. 글을 입력하거나 업로드하여 AI
              피드백의 보조를 받으실 수 있고, 키위런(학생) 계정과 연결하여
              학생들에게 글쓰기 과제를 부여할 수 있습니다. 키위런은 학생용
              서비스로, 키위티 선생님 계정과 연결하여야만 사용할 수 있습니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand2',
                );
                setExpand2(prev => !prev);
              }}
            >
              Q. 키위런 없이 키위티만 사용할 수 있나요?
            </Button>
            <Box
              style={{
                display: expand2 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위런(학생) 계정을 사용하지 않고도, 키위티를 사용하실 수
              있습니다. 다만, 최소한의 키위런(학생) 수의 요금제를 선택해주셔야
              합니다. 별도의 할인 혜택은 없습니다. (학생 3명 : 12개월 55만원,
              6개월 33만원)
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand3',
                );
                setExpand3(prev => !prev);
              }}
            >
              Q. 요금제는 어떻게 선택해야 하나요?
            </Button>
            <Box
              style={{
                display: expand3 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티 요금제는 사용 기간(6개월/12개월)과 담당 학생 수를 고려하여
              선택하셔야 합니다. 선택한 요금제의 학생 수 만큼, 키위런 계정을
              인증할 수 있습니다. 키위런 계정을 인증하셔야 해당 계정에 과제를 줄
              수 있습니다. 기관 및 학교 관계자는 우측의 ‘카카오톡 상담하기’를
              통해 그룹 이용권의 견적을 받아 진행하시기 바랍니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                APIUtils.LoggerLog(
                  authReducer.user_id,
                  'keewit',
                  'info',
                  window.location.pathname,
                  'click_event_expand4',
                );
                setExpand4(prev => !prev);
              }}
            >
              Q. 무료 체험을 해 볼 수 있나요?
            </Button>
            <Box
              style={{
                display: expand4 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위티 도입 전, 무료로 사용해 보고 싶으신가요?{' '}
              <span
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onClick={() => {
                  window.open('https://forms.gle/vq3jCb1tyQEywvXq6');
                }}
              >
                여기
              </span>
              를 클릭해서 설문에 응답해주세요. 1개월 이용권(선생님 1명, 학생
              30명)을 제공해드립니다.
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '2rem',
            flex: '6',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <img src={imgMain5} alt="imgMain5" style={{width: '80%'}} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
